.cJgFrV {
    background: rgb(0, 0, 0);
    position:fixed;
    width: 100%;
    top: 0px;
    z-index: 1001;
    max-width: 480px;
    touch-action: none;
}

@media (max-width: 991px)
{
.cEwOrG {
    background: rgb(34, 34, 34);
}
}
.cEwOrG.navbar {
    margin-bottom: 0px;
}
 .cEwOrG {
     position: relative;
     border-style: none;
     min-height: 64px;
     background: rgb(0, 0, 0);
     top: 0px;
     z-index: 1030;
 }

.cbssWR {
    transition: color 0.3s;
    font-size: 1.4rem;
    vertical-align: middle;
    margin: 0px 10px;
    width: 15px;
    padding: 5px 0px;
    color: rgb(136, 136, 136);
}
.visible-lg, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block, .visible-md, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-sm, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-xs, .visible-xs-block, .visible-xs-inline, .visible-xs-inline-block {
    display: none !important;
}
.kaxIlA {
    color: rgb(0, 0, 0);
}
@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }
}
@media (max-width: 991px) {
   .gpYNJZ .cbssWR {
        display: inline-block !important;
    }
}
.gpYNJZ .cbssWR {
    display: inline-block !important;
    overflow: hidden;
}

.cJgFrV .logo {
    display: inline-block;
    vertical-align: middle;
    line-height: 60px;
}
.gpYNJZ a, .gpYNJZ i {
    transition: color 0.3s;
}
.kaxIlA {
    color: rgb(0, 0, 0);
}

.cJgFrV .logo img {
    max-width: 205px;
    pointer-events: none;
    user-select: none;
    vertical-align: middle;
}
@media (max-width: 479px) {
    .cJgFrV .logo img {
        max-width: 110px;
        max-height: 45px;
        top: 7px;
    }
}
img {
    vertical-align: middle;
}
img {
    border: 0;
}
.cJgFrV .logo {
    line-height: 60px;
}

@media (max-width: 1200px) {
    .cJgFrV .profile-icons a.join-btn {
        padding: 8px 11px;
    }
}
.cJgFrV .profile-icons a.join-btn {
    background: rgb(249, 78, 155);
    color: rgb(255, 255, 255) !important;
    opacity: 1;
    text-transform: uppercase;
}
.gpYNJZ .image-btn, .gpYNJZ a:not(.btn):not(.active), .gpYNJZ .navbar-nav > li > a {
    color:#ffffff;
}

.cJgFrV .profile-icons > a {
    display: inline-block;
    line-height: 60px;
    margin: 0px;
    padding: 0px 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    vertical-align: middle;
}
@media (max-width: 479px) {
    .cJgFrV .profile-icons > a {
        padding: 0px 7px;
        font-size:16px;
    }
}
.gpYNJZ a, .gpYNJZ i {
    transition: color 0.3s;
}
a, .label {
    color: rgb(0, 0, 0);
}
.gpYNJZ .image-btn, .gpYNJZ a:not(.btn):not(.active), .gpYNJZ .navbar-nav > li > a {
    color: #ffffff;
}
.cJgFrV .profile-icons > a {
    display: inline-block;
    line-height: 60px;
    margin: 0px;
    padding: 0px 20px;
    text-decoration: none;
    transition: background-color 0.3s, color 0.3s;
    vertical-align: middle;
}
.cJgFrV a.join-btn {
    border-radius: 2px;
    padding: 10px 20px;
    font-weight: 600;
    line-height: normal;
}
.cJgFrV .sign-in {
    text-transform: capitalize;
}
@media (max-width: 479px) {
    .cJgFrV .profile-icons > a {
        padding: 0px 7px;
        font-size:16px;
    }
}

.gpYNJZ a, .gpYNJZ i {
    transition: color 0.3s;
}
.kaxIlA {
    color: rgb(0, 0, 0);
}



.iycrvY {
    -moz-box-align: center;
    align-items: center;
    background-color: rgb(109, 215, 206);
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    /*min-height: 40px;*/
    padding: 4px 0px;
    text-align: center;
}


.iipagV {
    color: rgb(0, 0, 0);
    text-decoration: none;
}


.iipagV > span {
    display: block;
    margin: 0px 2px;
    text-align: center;
}
.nav-header{
    display: flex;
    height: 42px;
    border-top: 1.5px solid #181422;
    border-bottom: 1.5px solid #181422;
    background-color: #181422;
    color: #ffffff;
}
.nav-header .nav-item{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nav-header .nav-item a{
    color: #ffffff;
}
.nav-header .nav-item.active{
    color: #ffffff;
    background-color:#f94e9b;
}
.user-avatar img{
    height: 30px;
    border-radius: 15px;
}
.panel-item{
    color: #ffffff;
    text-decoration: none;
    font-family: apercu;
    font-size: 14px;
    margin-bottom:15px;
}
.mobile-menu-drawer .adm-list{
    --border-bottom: solid 1px #fff;
    --active-background-color:none;
    --border-inner: solid 1px #fff;
    --border-top: solid 1px #fff;
}
.mobile-menu-drawer .adm-list-item-content{
    border-top:solid 1px #fff;
}
