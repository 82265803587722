.app {
  height: 100%;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
}

.block {
  width: 90%;
  height: 164vw;
  background: linear-gradient(#2d2d2d, #383838);
  border-radius: 10px;
  overflow-y: auto;

  .face {
    max-height: 53.3333vw;
    height: 53.3333vw;
    width: 100%;
  }

  .van-image {
    position: relative;
    display: inline-block;
    padding: 2.6667vw 5% 2.6667vw 5%;
  }

  .title {
    width: 80%;
    font-size: 14px;
    font-weight: 700;
    margin: 0 auto;
    color: #ffffff;
    line-height: 1.5;
    height: 12vw;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }

  .box {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background: #f9e1b3;
    border-radius: 30px;
    text-align: center;
    margin-left: 10%;
    margin-top: 15px;
    color: #b76a4e;
    font-size: 14px;
  }

  .box {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background: #f9e1b3;
    border-radius: 30px;
    text-align: center;
    margin-left: 10%;
    margin-top: 15px;
    color: #b76a4e;
    font-size: 14px;
  }

  .box {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background: #f9e1b3;
    border-radius: 30px;
    text-align: center;
    margin-left: 10%;
    margin-top: 15px;
    color: #b76a4e;
    font-size: 14px;
  }

  .box {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background: #f9e1b3;
    border-radius: 30px;
    text-align: center;
    margin-left: 10%;
    margin-top: 15px;
    color: #b76a4e;
    font-size: 14px;
  }

  .more {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background:#f94e9b;
    border-radius: 3px;
    text-align: center;
    margin-left: 10%;
    margin-top: 2.6667vw;
    color: #fff;
    font-size: 14px;
    letter-spacing: 4px;
  }

  .close-button {
    width: 30px;
    height: 30px;
  }

  .close-button img {
    width: 100%;
    height: 100%;
  }

  .office-site {
    text-align: center;
    color: #ffffff;
    margin: 10px 0;
  }

  .pop-button {
    position: sticky;
    bottom: 10px;
  }

  .buy {
    width: 80%;
    height: 30px;
    line-height: 30px;
    background: #07c160;
    border-radius: 3px;
    text-align: center;
    margin-left: 10%;
    margin-top: 15px;
    color: #fff;
    font-size: 14px;
    letter-spacing: 4px;
  }
}
@media (min-width: 769px) {
  .block {
    height:100%;
    .face{
      max-height:256px;
      height:256px;
    }
    .van-image {
      padding: 13px 5% 13px 5%;
    }
    .title{
      height: 58px;
    }
  }
}
.adm-mask {
  z-index: 1030 !important;
  max-width: 480px;
  transform: translate(-50%,0);
  left: 50%!important;
}

.adm-mask .adm-mask-content {
  position: absolute;
  height: 100%;
  z-index: 1032;
  width: 100%;
}

.adm-mask .item-list {
  padding: 10px;
}

.adm-mask .item-list .dYLMTo {
  margin-bottom: 10px;
}

.pop-button-close {
  display: flex;
  justify-content: flex-end;
  border-bottom: 0;
}

.adm-modal-wrap {
  z-index: 1033 !important;
}

.modal-form {
  border: 1px solid rgba(0, 0, 0, .07);
  border-radius: 5px;
  margin-bottom: 10px;

  .form-group {
    position: relative;
    background: #fff;
    padding: 8px 12px;
    border-radius: 5px;
    transition: all .2s ease;
    margin-bottom: 0;

    label {
      display: block;
      color: #b8babc;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 0;
      transition: all .2s ease;
    }

    .form-control {
      display: block;
      width: 100%;
      background: 0 0;
      color: #252525;
      font-size: 16px;
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
    }

    .form-flex {
      display: flex;
      align-items: center;
    }
  }

  .required::after {
    color: #ea2c54;
    content: "*";
    font-size: 20px;
    position: absolute;
    right: 12px;
    top: 6px;
  }

  .form-group:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, .07);
  }
}

.back-wrap {
  max-width: 720px;
  margin: 0 auto;
  padding: 10px;

  h3 {
    text-align: center;
    color: #ffffff;
  }

  .subtitle {
    text-align: center;
    line-height: 30px;
    color:#ffffff;
  }
}

.back-list {
  li {
    height: 56px;
    color: #ffffff;
    display: flex;
    border-radius: 5px;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 10px;
    border: 1px solid #333333;

    .url-left {
      flex: 6;
    }

    .test-time {
      flex: 3;
      text-align: center;
      color: #46d041;
    }

    .entry {
      flex: 3;
      text-align: center;

      a:hover {
        cursor: pointer;
        color: #fe628e;
      }

      a {
        color:#ffffff;
      }
    }
  }
}

.address-title {
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;

  a {
    color: #fe628e;
    margin-left: 10px;
  }
}
body {
  max-width: 480px;
  margin: 0 auto !important;
  position: relative;
  overflow-x: hidden;
}
