.root {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  :global {
    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .image-icon {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: #f7f8fa;
    }

    .icon {
      color: #dcdee0;
      font-size: 32px;
    }
  }
}
