


.kulCVZ {
    position: relative;
    padding-top: 36px;
    min-height: 298px;
    background: rgb(0, 0, 0);
    color: rgb(157, 157, 157);
}
@media only screen and (max-width: 1200px) {
    .kulCVZ {
        min-height: 472px;
    }
}
@media only screen and (max-width: 992px) {
    .kulCVZ {
        min-height: 472px;
    }
}
@media only screen and (max-width: 768px) {
    .kulCVZ {
        min-height: 528px;
    }
}
@media only screen and (max-width: 480px) {
    .kulCVZ {
        min-height: 550px;
    }
}
.footer-word{
    display: flex;
}
.tag-word,.search-word{
    flex: 1;
    display: flex;
    justify-content: center;
}
.footer-word .kTSifz {
    color: #ffffff;
}
.footer-word .bdpLBo {
    color: #ffffff;
    font-family: 'Apercu', arial, sans-serif;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    padding-bottom: 16px;
}
.footer-word .iKwwi {
    display: block;
    text-decoration: none;
    color: #ffffff;
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}
.container .adm-list-body{
    background: none;
}
.container .adm-list-default .adm-list-body{
    border-top:1px solid rgb(51, 51, 51);
    border-bottom:1px solid rgb(51, 51, 51);
}
.container .adm-list-item-content{
    border-top:1px solid rgb(51, 51, 51);
}
.container .adm-list{
    --active-background-color: rgb(51, 51, 51);
}
.adm-plain-anchor:active,.adm-plain-anchor:hover{
    color: #ffffff;
}
.container .adm-list-item-content-main{
    color: #ffffff;
}
.footer-utils{
    margin-top: 15px;
}
.about-us{
    margin-top: 15px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 50px;
}
.footer-logo{
    text-align: center;
    margin-bottom: 20px;
}
.footer-logo .logo{
    text-align: center;
    width: 80px;
}
.contact-item{
    margin-bottom: 10px;
}
.contact-item a{
    color: #f94e9b;
}
.question-item{
    margin-bottom: 10px;
}
.footer-pop .adm-popup-body-position-bottom{
    z-index: 1038;
}
.footer-pop .adm-mask-aria-button{
    z-index: 1037;
}
.footer-pop-content{
    padding: 15px;
    white-space: pre-line;
}
.footer-adv{
    position: relative;
}
.footer-adv .adv-wrap{
    display: block;
    position: absolute;
    left: 0;
    top: 0;
}
.footer-adv .close-button{
    position: absolute;
    right: 0;
    top: 0;
}
.footer-adv .close-button img{
    width: 22px;
    height: 22px;
}
