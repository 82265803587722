.video-wrap {
    position: relative;
.plyr--video {
.plyr__control--overlaid {
    background-color: #fe628ecc!important;
}
}

.plyr--video .plyr__control:hover {
    background: #fe628ecc!important;
}

.plyr--full-ui input[type=range] {
    color: #fe628ecc!important;
}

.loading {
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

img {
    width: 80px;
}
}
}