.root {
  height: 100%;

  :global {
    .message-list{
      padding: 0 15px;
      li{
        display: flex;
        height: 50px;
        justify-content: space-between;
        align-items: center;
        border-bottom:1px solid #dddddd;
       div{
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 33.333%;
         width: 33.333%;
        }
        .pop-content{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #00b2ff;
        }
      }
    }
  }
}

:global {

}
