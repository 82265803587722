* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  background-color: rgb(24, 20, 34);
}
:root:root {
  --adm-color-primary: #fc6627;
  --adm-color-success: #00b578;
  --adm-color-warning: #ff8f1f;
  --adm-color-danger: #ff3141;
  --adm-color-white: #ffffff;
  --adm-color-weak: #999999;
  --adm-color-light: #cccccc;
  --adm-border-color: #eeeeee;
  --adm-font-size-main: 13px;
  --adm-color-text: #333333;
}

//字体图标通用样式
.icon {
  width: 1em; height: 1em;
  fill: currentColor;
  overflow: hidden;
  vertical-align: middle;
}
