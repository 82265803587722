.yLshi {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}
.iDHATf {
    background-color: rgb(24, 20, 34);
}
.yLshi .MOVIE {
    max-width: 95%;
    flex: 100%;
}
.yLshi .MOVIE .movie-content{
    height: 114px;
}
.kaxIlA {
    background-color: rgb(24, 20, 34);
    /*overflow-x: hidden;*/
    padding-top: 142px;
}
.video-page{
    background-color: rgb(245, 245, 245);
    color: rgb(0, 0, 0);
    min-height: 100vh;
}
@media (max-width: 1024px)
{
    .yLshi {
        margin-left: 0px;
    }
}
@media (max-width: 768px) {
    .yLshi .MOVIE {
        max-width: 47.5%;
        flex: 100%;
    }
    .yLshi .MOVIE .movie-content{
        height: 26.5vw;
    }
}
.yLshi .MOVIE {
    max-width: 47.5%;
    flex: 100%;
}
@media (max-width: 430px) {
    .yLshi .MOVIE .movie-content{
        height: 26.5vw;
    }
}
.movie-text{
    color: #ffffff;
    padding: 10px 3% 10px 10px;
    width: 100%;
    max-width: 100%;
}
.relate-list .movie-text{
    color: #4a4a4a;
}
.yLshi .MOVIE {
    position: relative;
}
.yLshi .MOVIE .mode{
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px 0;
    height: 20px;
    z-index: 5;
    background:#f94e9b;
    color: #fff;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
}
.object-cover {
    -o-object-fit: cover;
    object-fit: cover;
    transition: all .3s;
    animation-name: lazyloaded;
    animation-duration: .5s;
}
@keyframes lazyloaded {
    from {
        -webkit-filter: blur(0.25rem);
        filter: blur(0.25rem)
    }
    to {
        -webkit-filter: blur(0);
        filter: blur(0)
    }
}




.dntOkG {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
}
.dfJZUS {
    color: rgb(255, 255, 255);
    font-family: Urbanist, "Open Sans", sans-serif;
    font-size: 24px;
    position: relative;
    text-decoration: none;
}
.dfJZUS:hover,.dfJZUS:active,.dfJZUS:focus{
    color: #ffffff;
}
.dfJZUS {
    font-size: 14px;
    height: 100%;
    width: 100%;
    overflow: hidden;
}
@media (max-width: 1024px) {
    .dfJZUS {
        font-size: 14px;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }
}
.dfJZUS img{
    border-radius: 5px;
}



@media (max-width: 1024px) {
    .ieXpgb {
        background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 50%);
        font-size: 48px;
    }
}


.ieXpgb {
    color: transparent;
    position: absolute;
    flex-basis: 100%;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 64px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: all 0.3s ease 0s;
    background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 30%);
}

@media (max-width: 1024px) {
    .ieXpgb img {
        max-width: 44px;
    }
}

.ieXpgb img {
    transition: all 0.3s ease 0s;
    opacity: 0;
}

img {
    vertical-align: middle;
}

@media (max-width: 1024px) {
    .dJBYtv {
        vertical-align: baseline;
    }
}

.dJBYtv {
    max-width: 100%;
}

@media (max-width: 1024px) {

    .bwSTre {
        padding: 10px 3% 12px 9px;
    }
}

.bwSTre {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 10px 3% 20px 20px;
    width: 100%;
    max-width:100%;
}

.ecirrm {
    display: block;
    font-family: Urbanist, "Open Sans", sans-serif;
    font-weight: 800;
    font-size: 28px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.ecirrm {
        font-size: 16px;
}
.yLshi .MOVIE .ecirrm {
    font-size: 15px;
}

.kZsUpz {
    display: block;
    font-family: Urbanist, "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.kZsUpz {
        font-size: 14px;
}
.hXzcCw {
    display: block;
    font-size: 14px;
    font-family: Urbanist, "Open Sans", sans-serif;
    font-weight: 400;
    color:#ffffff;
}
@media (max-width: 1024px)
{
.hXzcCw {
    font-size: 12px;
}
}


 .FGHoO {
     display: block;
     padding: 0 5px;
     height: 38px;
     line-height: 38px;
     border-radius: 10px;
     background: rgb(21, 20, 25);
     font-family: Urbanist, "Open Sans", sans-serif;
     font-weight: 600;
     font-size: 18px;
     text-align: center;
     position: absolute;
     right: 0px;
     bottom: 10px;
 }

@media (max-width: 1024px)
{
    .FGHoO {
        width: 60px;
        height: 21px;
        line-height: 21px;
        font-size: 11px;
        bottom: 5px;
    }
}
svg:not(:root).svg-inline--fa {
    overflow: visible;
}
 .svg-inline--fa.fa-w-18 {
     width: 1.125em;
 }
svg:not(:root) {
    overflow: hidden;
}
 .svg-inline--fa {
     display: inline-block;
     font-size: inherit;
     height: 1em;
     overflow: visible;
     vertical-align: -0.125em;
 }



.idSrqE.entered, .idSrqE.entering {
    transform: translateX(0px);
    opacity: 1;
}
.idSrqE.exiting {
    transform: translateX(-100%);
    opacity: 0;
}
.idSrqE {
    background:#252222;
    box-shadow: rgba(0, 0, 0, 0.3) 1px 0px 5px;
    height: calc(-60px + 100vh);
    left: 0px;
    overflow-y: scroll;
    position: absolute;
    transition: transform 0.3s ease 0s;
    z-index: 10;
    padding-bottom: 100px;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.bbeARo {
    width: 280px;
    transition: transform 0.3s;
    min-height: 100vh;
}
.hUCGbF {
    border-top: 1px solid rgb(215, 215, 215);
    display: flex;
    flex-direction: column;
}
.fSZBHP .image-btn, .fSZBHP a:not(.btn):not(.active), .fSZBHP .navbar-nav > li > a {
    color: rgb(157, 157, 157);
}
.hUCGbF a.join-now {
    order: -1;
}
.hUCGbF a {
    display: flex;
    -moz-box-align: center;
    align-items: center;
    border-bottom: 1px solid rgb(215, 215, 215);
    color: rgb(0, 0, 0);
    padding: 10px;
    text-decoration: none;
}
.fSZBHP a, .fSZBHP i {
    transition: color 0.3s;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}
.svg-inline--fa.fa-fw {
    width: 1.25em;
}
.svg-inline--fa.fa-w-20 {
    width: 1.25em;
}
svg:not(:root) {
    overflow: hidden;
}
.isxDUr {
    display: flex;
    font-size: 1.55rem;
    margin-right: 10px;
    width: 1.25em !important;
    height: 25px;
    -moz-box-align: center;
    align-items: center;
}
.fa-fw {
    text-align: center;
    width: 1.25em;
}
.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}
.iyioUw {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0);
    opacity: 0.6;
    z-index: 1000;
    display: block;
    touch-action: none;
}
 .fNjRST {
     min-height:128px;
     width: 100%;
     display: flex;
     -webkit-box-align: center;
     align-items: center;
     justify-content: flex-start;
     flex-wrap: wrap;
     background:#181422;
     padding-left: 10px;
     gap: 10px;
 }
@media (max-width: 1024px) {

    .fNjRST {
        min-height: 60px;
    }
}
@media (max-width: 430px) {
    .fNjRST {
        min-height: 50px;
    }
}
@media (max-width: 1024px) {
.kxdBff {
    font-size: 16px;
}
}
 .kxdBff {
     display: block;
     text-align: center;
     color:#ffffff;
     font-family: Urbanist, "Open Sans", sans-serif;
     font-weight: 700;
     font-size: 20px;
     text-transform: uppercase;
 }
 .loader-more{
     font-size: 16px;
     color:#f94e9b;
     font-weight: 700;
     line-height: 32px;
 }
 .iVzzFR {
     min-height: 150px;
     width: 100%;
     display: flex;
     -webkit-box-align: center;
     align-items: center;
     -webkit-box-pack: center;
     justify-content: center;
     flex-wrap: wrap;
     background:#181422;
 }
@media (max-width: 1024px) {
    .iVzzFR {
        min-height: 90px;
    }
}
.gdlclw {
    display: block;
    text-decoration: none;
    text-align: center;
    background: rgb(39, 33, 55);
    color: rgb(255, 255, 255);
    font-family: Urbanist, "Open Sans", sans-serif;
    font-weight: 600;
    font-size:16px;
    height: 45px;
    line-height: 45px;
    width: 380px;
    border-radius: 40px;
    text-transform: uppercase;
    margin: 0px auto;
    transition: all 0.3s ease 0s;
}
@media (max-width: 430px) {
    .gdlclw {
        font-size: 16px;
        width: 180px;
        height: 45px;
        line-height: 45px;
    }
}
@media (min-width: 0px) {
    .video-info {
        margin: 30px 30px 15px 30px;
    }
}
.video-page.kaxIlA{
    background: #ffffff;
}
.bbatoq {
    margin: 0;
    box-sizing: border-box;
}
.iZvjSX {
    margin: 0;
    color: #4a4a4a;
    font-family: apercu;
    font-weight: 900;
    line-height: 26px;
}
@media (min-width: 0px) {
    .iZvjSX {
        font-size: 18px;
    }
}
.hDzty {
    color: #757575;
    font-family: Apercu, arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}
.van-icon {
    position: relative;
    display: inline-block;
    font: 14px / 1 vant-icon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
}
.video-utils{
    margin-top: 15px;
}
.video-utils-item{
    margin-bottom: 20px;
    display: flex;
}
.video-utils-item #copybtn{
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}
.ifiHPs {
    background: #EBEBEB;
    width: 100%;
    height: 1px;
    margin-bottom: 20px;
}
.kUYGou {
    margin-bottom:10px;
}
.relate-list{
    padding: 0 20px;
    margin-bottom: 20px;
}
.gCNTYn {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: left;
    flex: 100%;
    padding-left: 10px;
}
.lLHvW {
    margin: 0;
    font-family: Apercu, arial, sans-serif;
    color: #141414;
    font-weight: 900;
}
.content {
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    user-select: none;
}
.hGwqzl {
    color: #757575;
    font-family: Apercu, arial, sans-serif;
    font-size: 14px;
    font-weight: 300;
    text-align: right;
    text-decoration: none;
}
.icons {
    margin-left: 10px;
}
.header-right{
    line-height: 60px;
    margin-right: 10px;
}
.account-page,.mobile-page{
    background-color: #f5f5f5;
}
.account-content,.mobile-content{
    flex: 1;
    padding:24px;
}
.mobile-header .gCNTYn{
    padding-left: 0;
}
.dxCrhe {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 24px;
}
.jqNckD {
    display: inline;
    margin: 0;
    text-align: left;
    padding: 12px 32px;
    background: #f94e9b;
    border-radius: 5px;
    text-decoration: none;
}
.dfBjvj {
    color: #fff;
    font-family: Apercu, arial, sans-serif;
    font-size:14px;
    font-weight: 600;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
}
.kUYGou {
    margin-bottom: 20px;
}
.ifiHPs {
    background: #EBEBEB;
    width: 100%;
    height: 1px;
}
.flayst {
    padding: 40px;
    margin-bottom: 32px;
    background-color: #fefefe;
    border-radius: 3px;
    box-shadow: 0 2px 5px -2px #0000004d;
}
.kUYGou {
    margin-bottom: 20px;
}
.cVvOCk {
    margin: 0;
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size:32px;
    font-weight: 900;
}
.kUYGou {
    margin-bottom:20px;
}
.bgPWRq {
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size: 16px;
    font-weight: 900;
    text-align: left;
    text-decoration: none;
}
.dnXEew {
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size: 16px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}
.dnXEew {
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size:16px;
    font-weight: 300;
    text-align: left;
    text-decoration: none;
}
.bind-btn-wrap{
    margin-top: 20px;
}
.gCNTYn {
    margin-bottom:16px;
}
.cVvOCk {
    margin: 0;
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size:32px;
    font-weight: 900;
}
.bhqtR {
    color: #9e9e9e;
    font-family: Apercu, arial, sans-serif;
    font-size:16px;
    line-height: calc(20px + .2vw);
    word-break: break-word;
}
.fPVyDJ {
    margin-bottom: 48px;
}
.BMDUb {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.jSzqLK {
    display: flex;
    flex-grow: 1;
    order: 2;
    height:48px;
    box-sizing: border-box;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    -webkit-transition-property: border-color;
    transition-property: border-color;
    background-color: #fff;
}
.NXyZC {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    font-size: 16px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 100;
    caret-color: #7c9fc4;
    padding: 8px 16px;
    color: #333;
    -webkit-transition-property: color;
    transition-property: color;
}

.irDggH {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    line-height: 1;
    margin: 0;
    overflow: visible;
}
.eGBVkV {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
}
.kwplws, .kwplws[type=button], .kwplws[type=reset], .kwplws[type=submit] {
    -webkit-appearance: button;
}
.eDWyDf {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 100%;
    -webkit-transition: color .1s ease-in;
    transition: color .1s ease-in;
    color: #ffffff;
}
.account-page .eDWyDf{
    width: 40px;
    color: #757575;
}
.kwplws {
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 100%;
    background: none;
    border: none;
    font-family: inherit;
    font-size: 100%;
    margin: 0;
    overflow: visible;
    text-transform: none;
}
.eZDHil {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: currentColor;
    font-style: normal;
    width: 22.4px;
    height: 22.4px;
}
.kBXcXC {
    fill: currentColor;
}
.jJQUgq {
    cursor: pointer;
    font-size: 14px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 300;
    color: #333;
    -webkit-transition: color .1s ease-in;
    transition: color .1s ease-in;
    order: 1;
    padding-bottom:8px;
}
.gxKEqq {
    margin-top: 8px;
    font-size: 14px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 300;
    color: #333;
    -webkit-transition: color .1s ease-in;
    transition: color .1s ease-in;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}
.feuTYo {
    margin-bottom:32px;
}
.iVbMDf {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
@media (min-width: 0px) {
    .dKmixv {
        width: 100%;
    }
}
.dKmixv {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0;
}
.kwplws, .kwplws[type=button], .kwplws[type=reset], .kwplws[type=submit] {
    -webkit-appearance: button;
}
@media (min-width: 0px) {
    .dxPGQA {
        width: 100%;
    }
}
.dxPGQA {
    height:40px;
    border: none;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    outline: none;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 600;
    border-radius: 0;
    box-sizing: border-box;
    line-height: .9;
    padding: 0 24px;
    color: #fefefe;
    background-color: #f94e9b;
    -webkit-transition-property: color, background-color, box-shadow;
    transition-property: color, background-color, box-shadow;
    text-decoration: none;
    border-radius: 5px;
}
.bNPNQH {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    gap: 3px;
    padding: 20px 30px;
}
.dYLMTo {
    width: 100%;
    border-radius: 2px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 14px 14px 14px 10px;
    background: #ffffff;
}
.bZSGlp {
    display: flex;
    flex-direction: column;
}
.lcOZYP {
    text-transform: uppercase;
    font-size:14px;
    font-weight: 600;
}
.YxulC {
    text-transform: uppercase;
    font-size: 10px;
    margin: -2px 0px;
}
.YxulC > p {
    margin: 0px;
    font-size: 10px;
}
.bvDcOs {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    line-height: 1;
    vertical-align: text-top;
    font-size: 32px;
}
.bHARMZ {
    font-size: 10px;
}
.emiDzr {
    font-size:16px;
    line-height: 32px;
    font-weight: 700;
}
.bzIzuX {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    align-items: flex-start;
}
.kspHzX {
    font-size:12px;
    font-weight: 700;
}
.gOOlIu {
    font-size: 8px;
}
.bBUPnm {
    width: 100%;
    margin-top: 12px;
}
 .hNSosq {
     padding: 10px;
     width: 100%;
     min-height: 48px;
     height: auto;
     display: flex;
     align-items: center;
     justify-content: center;
 }
 .iQMOeb {
     background:#f94e9b;
     border: 0px;
     color: white;
     cursor: pointer;
     display: block;
     font-size:12px;
     height: 48px;
     margin: 0px auto;
     padding: 2px 0px;
 }
 .item-active{
     width: 100%;
     border-radius: 2px;
     border: 3px solid #f94e9b;
     display: flex;
     -webkit-box-pack: justify;
     justify-content: space-between;
     -webkit-box-align: center;
     align-items: center;
     padding: 14px 14px 14px 10px;
     background: #272137;
     color: #ffffff;
 }
.vip-box {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
    background: #272137;
    overflow: hidden;
    cursor: pointer;
    padding: 8px 8px 8px 12px;
    border: 2px solid #f94e9b;
    margin-top: 16px;
    margin-bottom: 16px;
    margin-left: 0;
}
.video-box.vip-box{
    background-color: #ffffff;
    border: 2px solid #ffffff;
}
.video-box.vip-box.item-active{
    background:#272137;
    border: 2px solid #f94e9b;
}
.video-box.vip-box .box-left{
    color: #000000;
}
.video-box.vip-box .box-right{
    color: #000000;
}
.video-box.vip-box.item-active .box-left,.item-active.video-box.vip-box .box-right,.item-active .box-left,.item-active.video-box.vip-box .box-right{
    color: #ffffff;
}
.copy-box{
    border: none;
}
.box-left {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-family: Gilroy, arial, sans-serif;
    font-size:14px;
    font-weight: 900;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
}
.box-right {
    display: flex;
    min-width: 32px;
    min-height: 32px;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 8px 12px;
    margin-top: 0;
    position: relative;
    color:#ffffff;
    font-family: Gilroy, arial, sans-serif;
    font-size: 17.6px;
    font-weight: 700;
    line-height: .75;
    text-align: left;
}
.modal-wrap .pay-btns {
    display: flex;
    justify-content: space-between;
}
.modal-wrap .pay-btns a {
    display: flex;
    width: calc(50% - 5px);
    background-color:#ffffff;
    font-size: 13px;
    font-weight:600;
    border-radius: 5px;
    padding: 10px;
    border: 2px solid #ffffff;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    color: #000000;
}
.modal-vip-box{
    background: #ffffff;
}
.modal-vip-box .box-left{
    color: #000000;
}
.modal-vip-box .box-right{
    color: #000000;
}
.modal-wrap .pay-btns img {
    height: 20px;
    width: auto;
    margin-right: 10px;
}
.modal-wrap .subtitle{
    color: #8e9194;
    margin-bottom: 10px;
    font-size: 14px;
    margin-top: 10px;
}
.flayst {
    padding: 40px;
    margin-bottom: 32px;
    background-color: #fefefe;
    border-radius: 3px;
    box-shadow: 0 2px 5px -2px #0000004d;
}
.kUYGou {
    margin-bottom:20px;
}
.gCNTYn {
    margin-bottom: 16px;
}
.cVvOCk {
    margin: 0;
    color: #333;
    font-family: Apercu, arial, sans-serif;
    font-size:32px;
    font-weight: 900;
}
.fPVyDJ {
    margin-bottom: 48px;
}
.kUYGou {
    margin-bottom: 20px;
}
.BMDUb {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.jSzqLK {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    order: 2;
    height: 40px;
    box-sizing: border-box;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    -webkit-transition-property: border-color;
    transition-property: border-color;
    background-color: #fff;
}
.NXyZC {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    font-size: 16px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 100;
    caret-color: #7c9fc4;
    padding: 8px 16px;
    color: #333;
    -webkit-transition-property: color;
    transition-property: color;
}
.irDggH {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    line-height: 1;
    margin: 0;
    overflow: visible;
}
.jJQUgq {
    cursor: pointer;
    font-size:14px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 300;
    color: #333;
    -webkit-transition: color .1s ease-in;
    transition: color .1s ease-in;
    -ms-flex-order: 1;
    order: 1;
    padding-bottom: 8px;
}
.iVbMDf {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.BMDUb {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}
.jSzqLK {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    order: 2;
    height: 40px;
    box-sizing: border-box;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    -webkit-transition-property: border-color;
    transition-property: border-color;
    background-color: #fff;
}
.NXyZC {
    flex-grow: 1;
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    -webkit-transition-duration: .1s;
    transition-duration: .1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    font-size: 16px;
    font-family: Apercu, arial, sans-serif;
    font-weight: 100;
    caret-color: #7c9fc4;
    padding: 8px 16px;
    color: #333;
    -webkit-transition-property: color;
    transition-property: color;
}

.irDggH {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    line-height: 1;
    margin: 0;
    overflow: visible;
}
.mobile-content .jSzqLK .eGBVkV,.modal-form .eGBVkV{
    display: flex;
    width: 108px;
    background-color: #07c160;
    border: 1px solid #07c160;
    color: #fff;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
}
.modal-form .eGBVkV{
    height: 34px;
}
.kwplws {
    padding: 0;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 100%;
    font-size: 100%;
    margin: 0;
    overflow: visible;
}
.mobile-menu-drawer .adm-list-body{
    /*min-height: 100vh;*/
    background: #000000;
}
.mobile-menu-drawer .link-item{
    padding-left: 12px;
    font-size: 16px;
}
.mobile-menu-drawer .link-item .adm-list-item-content-main{
    color: #f94e9b;
}
.adm-tabs .adm-button:not(.adm-button-default) {
    --background-color:#f94e9b;
    --border-color:#f94e9b;
}
.adm-modal.login-modal{
    z-index: 1001;
}
.login-modal .adm-tabs-tab-active{
    color: #f94e9b;
}
.login-modal .adm-tabs-tab-line {
    color: #f94e9b;
    background: #f94e9b;
}
.gqNXNr {
    margin: 10px;
    top: 0px;
    display: flex;
    flex-direction: column;
}
.DFPHx {
    position: relative;
}
svg:not(:root).svg-inline--fa {
    overflow: visible;
}
 .svg-inline--fa.fa-w-16 {
     width: 1em;
 }
 .DFPHx svg {
     position: absolute;
     right: 10px;
     top: 11px;
     opacity: 0.5;
 }

svg:not(:root) {
    overflow: hidden;
}
 .svg-inline--fa {
     display: inline-block;
     font-size: inherit;
     height: 1em;
     overflow: visible;
     vertical-align: -0.125em;
 }
.kaxIlA textarea, .kaxIlA input:not(.btn) {
    background: rgb(255, 255, 255);
    color: rgb(85, 85, 85);
}
 .kaxIlA .form-control {
     color: rgb(85, 85, 85);
 }
 .DFPHx .search-term {
     padding-right: 30px;
 }
 .gqNXNr input {
     border-width: 1px;
     border-style: solid;
     border-radius: 2px;
     border-color: rgb(136, 136, 136);
     font-size: 16px;
     height: auto;
 }
.form-control:focus {
    border-color: #66afe9;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
    outline: 0;
}
.jtybvW {
    margin: 0;
    font-family: Apercu, arial, sans-serif;
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 3px;
    color: #ffffff;
    margin-bottom: 20px;
    margin-left: 10px;
}
.bLAQEM {
    margin-bottom: 24px;
    padding: 0 10px;
}
.AgPoo {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
}
.jwdmKA {
    margin: 6px;
}
.kBXcXC {
    fill: currentColor;
}
.kaYYcQ {
    width: 100%;
    padding: 0;
    margin: 0 8px 0 0;
    font-size: 16px;
    font-family: apercu, sans-serif;
    font-weight: 300;
    border: none;
    background-color: transparent;
    outline: none;
    height: 30px;
}
.irDggH {
    overflow: visible;
}
.cate-title {
    font-family: apercu;
    color: #ffffff;
    margin: 24px 0;
    padding-left: 10px;
}
.sort-wrap {
    display: flex;
    justify-content: flex-start;
    margin-left: 10px;
    margin-bottom: 20px;
}
.sort-wrap a.active {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height:36px;
    padding: 14px;
    border: 1px solid #f94e9b;
    border-radius: 100px;
    font-family: Apercu;
    font-size: 14px;
    line-height: 10px;
    font-weight: 700;
    margin-right: 12px;
    background-color: #f94e9b;
    color: #fff;
    text-transform: none;
    white-space: nowrap;
}
.sort-wrap a {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    height: 36px;
    padding:14px;
    border: 1px solid #333333;
    background: #181422;
    border-radius: 100px;
    font-size: 14px;
    line-height: 10px;
    font-weight: 700;
    margin-right: 12px;
    color: #ffffff;
    text-transform: none;
    white-space: nowrap;
}
a:focus, a:hover{
    text-decoration: none;
}
.banner{
    margin-bottom: 15px;
}
.mb-10{
    margin-bottom: 10px;
}
.mt-10{
    margin-top: 10px;
}
.mt-20{
    margin-top: 20px;
}
a:focus, input:focus, button:focus, textarea:focus, [class*=van-]:focus {
    outline: none;
}
.NXyZC:active, .NXyZC:focus {
    color: #424242;
    border: 1px solid #00b2ff;
}
.recharge-modal .adm-modal-body{
    background-color:#272137;
}
.login-modal .adm-modal-body{
    background-color: #f5f7fd;
}
.recharge-modal .adm-modal-body .adm-modal-title{
    color: #ffffff;
}
.recharge-modal .adm-modal-body .adm-modal-close{
    color: #ffffff;
}
.hGwqzl .feedback-icon{
    vertical-align: middle!important;
}
.form-item  .form-item-inner{
 border-radius: 5px;
 padding: 10px;
 background: #ffffff;
 margin-bottom: 10px;
}
.form-item .adm-button-primary{
    background-color:#f94e9b;
}
html,body,#root{
    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased
}
.recharge-modal{
    z-index: 1050!important;
}
.recharge-modal .adm-modal-wrap{
    z-index: 1052!important;
}
.recharge-modal .adm-mask{
    z-index: 1050!important;
}
.water-mark {
    position: absolute;
    height: 40px;
    top: 10px;
    right: 30px;
}
.adm-tabs-tab-list .adm-tabs-tab-active {
    color:#f94e9b;
}
.adm-tabs-tab-list .adm-tabs-tab-line{
    color:#f94e9b;
    background: #f94e9b;
}
.adm-tabs-tab-list .adm-tabs-tab{
     color: #ffffff;
}
.adm-tabs .adm-tabs-header{
    border-bottom: none;
}
.kaxIlA .cat-top{
    margin-top: 64px;
}
.adm-tabs-header-mask-right,.adm-tabs-header-mask-left{
    display: none;
}
.kaxIlA.cat-padding{
    padding-top: 184px;
}
.switch-modal .adm-tabs-tab-list .adm-tabs-tab{
    color: #333333;
}
.price-box{
    position: relative;
}
.price-box .icon-hot{
    height: 36px;
    position: absolute;
    right: -17px;
    top: -20px;
}