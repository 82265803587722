.root {
  display:flex;
  padding:20px 30px;
  justify-content:center;
  :global {
    .page-content{
      display: flex;
      height:48px;
      max-width: 100%;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      border-radius: 32px;
    }
    .page-last{
      border-radius: 0 5px 5px 0
    }
    .page-previous{
      border-radius: 5px 0 0 5px;
    }
    .page-previous,.page-last{
        position: relative;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        flex-shrink: 0;
        min-width: 30px;
        height: 100%;
        width: 56px;
        line-height: 1;
        text-align: center;
        cursor: pointer;
        text-decoration: none;
        border: 1px solid rgb(224, 224, 224);
        background-color: #272137;
    }
    .page-list{
      display: flex;
      height: 100%;
      -webkit-box-align: center;
      align-items: center;
      margin-right: -1px;
      margin-left: -1px;
      overflow: auto hidden;
    }
    .page-item{
      position: relative;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      flex-shrink: 0;
      min-width: 30px;
      height: 100%;
      width:56px;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      text-decoration: none;
      border: 1px solid rgb(224, 224, 224);
      background-color:#272137;
    }
    .page-item-number{
      color:#e0e0e0;
      font-family: Apercu, arial, sans-serif;
      font-size: 16px;
      font-weight: 300;
      text-align: left;
      text-decoration: none;
    }
    .page-item.page-current{
      background-color: #eee;
    }
    .page-item.page-current .page-item-number{
      color: #333;
      font-weight: 900;
    }
  }
}
