.marquee-container {
  width: 100%;

  .marquee-wrapper {
    height: 30px;
    overflow: hidden;

    .marquee-content {
      transform: translateY(0);
      transition: transform 1s;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 10px;

      .marquee-content-itm {
        display: block;
        line-height: 30px;
        font-size: 15px;
        color: #000;
      }
    }
  }
}
